import React from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { breakPoints } from '../theme/variables';
import ContanctForm from '../components/contact-form';

const FormsRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 4rem 2rem 3rem;

  @media (max-width: ${breakPoints.first}) {
    flex-direction: column;
    align-items: center;

    form + form {
      margin-top: 2em;
    }
  }
`;

const Apply = () => (
  <Layout>
    <SEO title="Jelentkezés" />
    <FormsRow>
      <ContanctForm apply />
      <ContanctForm apply personalMessage />
    </FormsRow>
  </Layout>
);

export default Apply;
